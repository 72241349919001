import React, { useEffect, useRef, useState } from "react"
import { Script } from "gatsby"
import Layout from "../../components/_App/layout"
import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Partner from "../../components/Common/Partner"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import * as loanStyled from "../../assets/css/loan-eim-calculator.module.css"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Breadcrumbs from "../../components/Common/Breadcrumbs"

const parent = [{
  name: "Tools",
  link: 'tools',
}];

ChartJS.register(ArcElement, Tooltip, Legend);



const LoanEmiCalculator = () => {

  const [amount, setAmount] = useState(1000000);
  const [loanPeriod, setLoanPeriod] = useState(5);
  const [interest, setInterest] = useState(9.75);
  const [emiAmount, setEmiAmount] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loanType , setLoanType] = useState('year');

  const data = {
    labels: ['Total Interest', 'Principle Amount'],
    datasets: [
      {
        label: '',
        data: [totalInterest, amount],
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  function calculateEMI(principal, loanPeriod, interestRate, loanType) {
    const monthlyInterestRate = interestRate / 12 / 100;
    let loanPeriodInMonths;

    if (loanType === 'year') {
      loanPeriodInMonths = loanPeriod * 12;
    } else if (loanType === 'month') {
      loanPeriodInMonths = loanPeriod;
    } else {
      // Handle invalid loanType value
      return;
    }

    if (Number(interestRate) === 0) {
      const emi = principal / loanPeriodInMonths;
      const totalInterestPay = 0;
      const totalAmountPay = principal;

      setTotalInterest(totalInterestPay);
      setTotalAmount(totalAmountPay);
      setEmiAmount(emi);
    } else {
      const numerator = principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanPeriodInMonths);
      const denominator = Math.pow(1 + monthlyInterestRate, loanPeriodInMonths) - 1;

      if (numerator === 0 && denominator === 0) {
        setEmiAmount(0);
        return;
      }

      const emi = numerator / denominator;
      const totalInterestPay = emi * loanPeriodInMonths - principal;
      const totalAmountPay = emi * loanPeriodInMonths;

      setTotalInterest(totalInterestPay);
      setTotalAmount(totalAmountPay);
      setEmiAmount(emi);
    }
  }

  const handleAmountChange = (event) => {
      setAmount(event.target.value);
  };

  useEffect(() => {
    calculateEMI(amount, loanPeriod, interest, loanType);
  }, [interest, loanPeriod, amount, loanType])

  return (
    <Layout>
    <Seo title="Free Loan EMI Calculation Tool"/>

    <Navbar/>
    <div className="contact-area ptb-80">
      <div className="container py-5">
        <div className="section-title">
          <h1>Loan EMI Calculator</h1>
          <div className="bar"></div>
        </div>
        <Breadcrumbs pageTitle="Loan EMI Calculator" parent={parent} />
        <div className="row align-items-center">
          <div className="col-12"></div>
          <div className="col-12">
            <div className="container">
                <div>
                  <div className={loanStyled.topBox}>
                    <div>
                      <div className="mb-3">
                        <p className={loanStyled.label}>Amount</p>
                        <input
                          className={loanStyled.emiInput}
                          type="text"
                          id="loan-amount"
                          value={amount}
                          onChange={handleAmountChange}
                        />
                      {/*<input*/}
                      {/*  type="range"*/}
                      {/*className={loanStyled.emiInput}*/}
                      {/*  id="loan-amount"*/}
                      {/*  min={0}*/}
                      {/*  max={10000000}*/}
                      {/*  step={50000}*/}
                      {/*  onChange={(e) => setAmount(e.target.value)}*/}
                      {/*/>*/}
                    </div>
                      <div className="mb-3">
                        <p className={loanStyled.label}>Loan Period</p>
                      <input
                        type="text"
                        id="loan-period"
                        className={loanStyled.emiInput}
                        title={loanPeriod}
                        value={loanPeriod}
                        onChange={(e) => setLoanPeriod(e.target.value)}
                      />
                        <div className={loanStyled.loanType}>
                          <span className={loanType === 'year' ? loanStyled.active : ''} onClick={() => setLoanType("year")}>Year</span>
                          <span className={loanType === 'month' ? loanStyled.active : ''} onClick={() => setLoanType("month")}>Month</span>
                        </div>
                      {/*<input*/}
                      {/*  type="range"*/}
                      {/*  id="loan-period"*/}
                      {/*className={loanStyled.emiInput}*/}
                      {/*  title={loanPeriod}*/}
                      {/*  min={1}*/}
                      {/*  max={30}*/}
                      {/*  step={1}*/}
                      {/*  onChange={(e) => setLoanPeriod(e.target.value)}*/}
                      {/*/>*/}
                    </div>
                      <div>
                        <p  className={loanStyled.label}>% Interest</p>
                      <input
                        type="text"
                        id="interest-rate"
                        className={loanStyled.emiInput}
                        min={1}
                        value={interest}
                        onChange={(e) => setInterest(e.target.value)}
                      />
                      {/*<input*/}
                      {/*  type="range"*/}
                      {/*  id="interest-rate"*/}
                      {/*className={loanStyled.emiInput}*/}
                      {/*  min={1}*/}
                      {/*  max={15}*/}
                      {/*  step="0.5"*/}
                      {/*  onChange={(e) => setInterest(e.target.value)}*/}
                      {/*/>*/}
                    </div>
                      <div className={loanStyled.priceContainer}>
                        <span>EMI Amount: {emiAmount.toLocaleString('en-IN')} /mo</span>
                        <span>Total Interest Amount: {totalInterest.toLocaleString('en-IN')}</span>
                        <span>Total Amount: {totalAmount.toLocaleString('en-IN')}</span>
                      </div>
                    </div>
                    <div className={loanStyled.chartBoxMain}>
                      <div className={loanStyled.chartBox}>
                      <Doughnut data={data} options={{
                        plugins: {
                          legend: {
                            position: 'bottom',
                            labels: {
                            }
                          }
                        }
                      }} />
                      </div>

                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div className="">
      <div className="page-steps container">
      </div>
    </div>


    <Partner/>

    <MilestonesAchievedSoFar/>

    <Footer/>
  </Layout>)
}

export default LoanEmiCalculator
